const stripe = Stripe('pk_live_BRMDICDAiHT937xKt9As5yLV')
const checkoutButton = document.querySelector('#checkout')

checkoutButton.addEventListener('click', _ => {
    fetch('/api/stripe', {
        method: 'POST'
    })
    .then(response => {
        return response.json()
    })
    .then(session => {
        return stripe.redirectToCheckout({ sessionId: session.id})
    })
    .then(result => {
        if(result.error) {
            alert(result.error.message)
        }
    })
    .catch(error => {
        console.error('Error:', error)
    })
})